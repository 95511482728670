<template>
  <div class="container mx-auto px-3 lg:p-0">
    <div class="flex justify-between items-center">
      <h3 class="page-title mb-5">Check Your Payment Status</h3>
      <button
        @click="exportCSV($event)"
        class="main-btn main-btn-lg"
        v-if="isSuccessfull && loads.length >= 1"
      >
        <svg class="icon">
          <use xlink:href="#excel"></use></svg
        >Export to excel
      </button>
    </div>
    <div class="ftl-wrapper">
      <form action="#" @submit.prevent="getFTLDetails">
        <div class="lg:flex justify-center">
          <div class="form-group">
            <label>LDI Load #</label>
            <input
              type="number"
              class="form-control"
              v-model="v$.form.loadid.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.loadid.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="and">AND</div>
          <div class="form-group">
            <label>MC#/USDOT#/DOT#</label>
            <input
              type="text"
              class="form-control mr-3"
              v-model="v$.form.mcusdot.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.mcusdot.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="btn-wrapper">
            <button class="main-btn mr-2" type="submit">
              <svg class="icon">
                <use xlink:href="#search-icon"></use>
              </svg>
            </button>
            <button class="btn" type="button" @click="clear">
              <svg class="icon">
                <use xlink:href="#close-icon"></use>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="no-loads mb-3">{{ responseText }}</div>
     <div v-if="loading">
      <div class="ribbon">Please wait patiently. We are working on your results.</div>
        <!-- <LoaderSkeleton></LoaderSkeleton> -->
      </div>
    <div class="table-responsive">
      <DataTable
        :value="loads"
        :paginator="true"
        :rows="10"
        sortMode="multiple"
        v-if="isSuccessfull"
        ref="dt"
        v-show="!this.loading"
        exportFilename="FTL Data"
        @rowSelect="onRowSelect"
         selectionMode="single"
          dataKey="id"
      >
        <Column field="LoadID" header="Load #" :sortable="true"></Column>
        <Column
          field="PickupDate"
          header="P/U Date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.PickupDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.PickupDate)
            }}
          </template>
        </Column>
        <Column field="OriginCity" header="Origin" :sortable="true"></Column>
        <Column
          field="DropDate"
          header="Del Date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.DropDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.DropDate)
            }}
          </template>
        </Column>
        <Column
          field="DestinationCity"
          header="Destination"
          :sortable="true"
        ></Column>
        <Column
          field="ExportedDate"
          header="Date Paperwork Approved"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.ExportedDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.ExportedDate)
            }}
          </template>
        </Column>
        <Column
          field="PaymentDate"
          header="Expected Pay Date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.PaymentDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.PaymentDate)
            }}
          </template>
        </Column>
        <Column field="Amount" header="Amount" :sortable="true">
          <template #body="{ data }">
            ${{ data.Amount }}
          </template>
        </Column>
        <Column
          field="PaymentType"
          header="Payment Type"
          :sortable="true"
        ></Column>
         <Column
          field="CheckNumber"
          header="Check #"
          :sortable="true"
        ></Column>
        <Column field="Exception" header="Exception" :sortable="true"></Column>
        <Column field="PaymentStatus" header="Status" :sortable="true"></Column>
      </DataTable>
    </div>
    <ContactDialogue ref="saveConfirmDialogue"></ContactDialogue>
  </div>
</template>
<script>
import { dataProvider } from "@/api/dataProvider";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ContactDialogue from "@/components/common/ContactDialogue.vue";

// this will be export a excel and the file's name is user-info-data.xlsx
// the default file's name is excel.xlsx

export default {
  data() {
    return {
      v$: useVuelidate(),
      isSuccessfull: false,
      load: "",
      pudate: "",
      origin: "",
      deldate: "",
      destination: "",
      paperworkapproveddate: "",
      estpaydate: "",
      amount: "",
      paymenttype: "",
      CheckNumber: "",
      exception: "",
      status: "",
      isNoLoads: false,
      form: {
        loadid: "",
        mcusdot: "",
      },
      responseText: "",
      loading: false,
    };
  },
   components: {
    ContactDialogue,
  },
  validations() {
    return {
      form: {
        loadid: {
          required: helpers.withMessage("LDI Load # is required", required),
        },
        mcusdot: {
          required: helpers.withMessage(
            "MC#/USDOT#/DOT# is required",
            required
          ),
        },
      },
    };
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    WithoutTime(dateTime) {
      var d = dateTime;
      d = d.split(" ")[0];
      return d;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async getFTLDetails() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.loading = true;
      var ftlDetails = await dataProvider.getFTLDetails(
        this.form.loadid,
        this.form.mcusdot
      );
      this.responseText = ftlDetails.ResponseText;
      (this.loads = ftlDetails.Loads),
        (this.isSuccessfull = ftlDetails.IsSuccessful);
        this.loading = false;
    },

    clear() {
      this.form.loadid = "";
      this.form.mcusdot = "";
    },
     onRowSelect() {
      this.$refs.saveConfirmDialogue.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.ftl-wrapper {
  label {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
  }
  .main-btn {
    padding: 10px;
    .icon {
      fill: #fff;
      width: 15px;
      height: 15px;
    }
  }

  .btn {
    padding: 10px;
    .icon {
      width: 15px;
      height: 15px;
    }
  }
  .btn-wrapper {
    margin-top: 26px;
  }
  .and {
    min-width: 50px;
    text-align: center;
    margin-top: 32px;
    color: #181818;
    font-weight: 700;
  }
  .error-msg {
    color: red;
  }
}
.main-btn-lg {
  padding: 10px 30px;
  .icon {
    display: inline-block;
    fill: #fff;
    margin-right: 6px;
  }
}
.table-custom {
  table-layout: fixed;
  width: 100%;
  tr {
    td {
      padding: 6px 8px;
      background: #fff;
      border: 1px solid #ddd;
      word-wrap: break-word;
      text-align: center;
    }
    th {
      padding: 6px 8px;
      background: #f8e3df;
      border: 1px solid #ddd;
      word-wrap: break-word;
      font-size: 14px;
    }
  }
}
.main-btn {
  padding: 10px;
  .icon {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
}
.main-btn-lg {
  padding: 10px 30px;
}
.btn {
  padding: 10px;
  .icon {
    width: 15px;
    height: 15px;
  }
}
.width-80 {
  width: 80%;
  margin: 0 auto;
}
.no-loads {
  font-size: 21px;
  color: #181818;
}

@media (min-width: 992px) {
  .ftl-wrapper {
    .form-control {
      width: 250px;
      margin-bottom: 5px;
    }
  }
}
</style>
